import { Auth } from "aws-amplify";
import React, { useState } from "react";
import fetchApi from "../../lambda";
import { globalAlert } from "../../utils/commonFuntions/commonFuntions";
import HeaderNominee from "./HeaderNominee";

export default function Nominee() {
  const [spinner, setSpinner] = useState(false);
  const getUrl = async () => {
    setSpinner(true);
    try {
      const token = await Auth.currentSession().then((data) =>
        data.getIdToken().getJwtToken()
      );
      const resp = await fetchApi("payrollExport", "GET", token);
      window.open(resp?.data?.fileUrl, "_blank", "noopener,noreferrer");
      setSpinner(false);
    } catch (error: any) {
      console.error("error: ", error);
      globalAlert("error", "No se pudo generar el excel", error?.response?.data?.message);
      setSpinner(false);
    }
  };
  return (
    <>
      <HeaderNominee />
      <div className="text-center">
        <h1>Bienvenido al módulo de Nómina</h1>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => (spinner ? () => {} : getUrl())}
        >
          {spinner ? (
            <div>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Descargando...
            </div>
          ) : (
            <>Descargar reporte</>
          )}
        </button>
      </div>
    </>
  );
}
