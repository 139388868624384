import { pagToken } from "./commonFuntions/commonFuntions";

export const listOperatorByEmail = (data) => {
  return `
    query {
      listOperators(limit: 1000000, filter: {
        corporateEmail:{
         eq: "${data}"
        }
      }) {
        items {
          id
          corporateEmail
          rol
          status
        }
      }
    }
    `;
};

export const listAllUsers = `
  query {
    listOperators(limit: 1000000) {
      items {
        id
        name
        surname
        rol
        status
        position {
          name
        }
        area {
          name
        }
        mainPhone
        corporateEmail
        operatorEpsId
        operatorAreaId
        operatorAfpId
      }
    }
  }
  `;

export const listOperators = `
query {
  listOperators(limit: 1000000, filter: {
    rol: {
      eq: Operator
    }
  }) {
    items {
      id
      name
      surname
    }
  }
}
`;

export const listAllPosition = `
query {
  listPositions(limit: 1000000) {
    items {
      id
      name
      salary
      bonuses {
        items {
          bonus {
            id
            name
            value
            isPercentage
            type
            isCreated
          }
        }
      }
    }
  }
}
`;

export const listByDocNumber = (number) => {
  return `
  query {
    listOperators(limit: 1000000, filter: {
      docNumber: {
        eq: "${number}"
      }
    }) {
      items {
        docNumber
      }
    }
  }
  `;
};

export const listAllEps = `
  query{
    listEps{
      items{
        id
        name
      }
    }
  }
  `;

export const listOperatorByAreaId = (data) => {
  return `
  query {
    listOperators(limit: 1000000, filter: {
      operatorAreaId: {
        contains: "${data}"
      }
    }) {
      items {
        id
        name
        operatorAreaId
      }
    }
  }
  `;
};

export const listWeellByJobNUmberId = (data) => {
  return `
  query {
    listWells(limit: 1000000, filter: {
      jobNumberWellsId: {
        contains: "${data}"
      }}) {
      items {
        id
        isUltrabasic
        name
        jobNumberWellsId
      }
    }
  }
  `;
};

export const validateWeelName = (data) => {
  return `
  query {
    listWells(limit: 1000000, filter: {
      name: {
        eq: "${data}"
      }
    }) {
      items {
        id
      }
    }
  }
  `;
};

export const listBonusByPosition = `
query {
  listBonuses(limit: 1000000, filter: {
    or: [
      {
        type: {
          eq: FB
        }
      },
      {
        type: {
          eq: B
        }
      },
      {
        type: {
          eq: EFB
        }
      }
    ]
  }) {
    items {
     	  id
        name
        type
        value
        isPercentage
        isCreated
        createdAt
        updatedAt
    }
  }
}
`;

export const getIdByEmail = (email) => {
  return `
  query {
    listOperators(limit: 1000000, filter: {
      corporateEmail: {
        eq: "${email}"
      }
    }) {
      items {
        id
      }
    }
  }
  `;
};

export const valDailyWorkReport = async (id, initialDate) => {
  const items =
    "id dateReport duty well{ name jobNumber { id name } } operator { name }";
  const resp = await pagToken(
    "listDailyWorkReports",
    "operatorDailyWorkReportsId",
    id,
    initialDate,
    items
  );
  return resp;
};

export const dailyReportListById = (id, token, initialDate, finalDate) => {
  return `
  query {
    listDailyWorkReports(limit: 1000000, filter: {
      operatorDailyWorkReportsId: {
        eq: "${id}"
      }, and: {
        dateReport: {
          between: ["${initialDate}", "${finalDate}"]
        }
      }
    }, nextToken: ${token ? `"${token}"` : null}) {
      items {
        id
        dateReport
        duty
        well{
          name
          jobNumber {
            id
            name
          }
        }
        operator {
          name
        }
      }
      nextToken
    }
  }
  `;
};
// export const dailyReportListById = (id, initialDate, finalDate) => {
//   return `
//   query {
//     listDailyWorkReports(limit: 1000000, filter: {
//       operatorDailyWorkReportsId: {
//         contains: "${id}"
//       }, and: {
//         dateReport: {
//           between: ["${initialDate}", "${finalDate}"]
//         }
//       }
//     }) {
//       items {
//         id
//         dateReport
//         duty
//         well{
//           name
//           jobNumber {
//             id
//             name
//           }
//         }
//         operator {
//           name
//         }
//       }
//     }
//   }
//   `;
// };

export const valAdminReport = async (id, initialDate) => {
  const items =
    "id dateReport duty entry out well{ name jobNumber { id name } } operator { name }";
  const resp = await pagToken(
    "listAdminReports",
    "operatorAdminWorkReportsId",
    id,
    initialDate,
    items
  );
  return resp;
};

export const adminDailyReportListById = (id, token, initialDate, finalDate) => {
  const query = `
  query {
    listAdminReports(limit: 1000000, filter: {
      operatorAdminWorkReportsId: {
        eq: "${id}"
      }, and: {
        dateReport: {
          between: ["${initialDate}", "${finalDate}"]
        }
      }
    }, nextToken: ${token ? `"${token}"` : null}) {
      items {
        id
        dateReport
        duty
        entry
        out
        well{
          name
          jobNumber {
            id
            name
          }
        }
        operator {
          name
        }
      }
      nextToken
    }
  }
  `;
  return query;
};

export const listOperatorByRol = `
query {
  listOperators(limit: 1000000, filter: {
    rol: {
      eq: Operator
    }, and: {
      status: {
        eq: Active
      }
    }
  }){
    items {
        id
        docType
        docNumber
      	gender
        name
        surname
        corporateEmail
        birthDate
      	position {
          name
        }
        calculatedPayroll
      }
  }
}
`;

export const validateOperatorByPosition = (id) => {
  return `query {
    listOperators(limit: 1000000, filter: {
      operatorPositionId: {
        eq: "${id}"
      }
    }) {
      items {
        id
        name
      }
    }
  }
`;
};

export const getPositionById = (id) => {
  return `
  query {
    listPositions(limit: 1000000, filter: {id: {eq: "${id}"}}) {
      items {
        id
        name
        salary
        bonuses {
          items {
            bonus {
              id
              name
              isPercentage
              value
              type
              isCreated
            }
          }
        }
      }
    }
  }
  `;
};

export const valPositionName = (name) => {
  return `
  query list {
    listPositions(limit: 100000, filter: {
      name: {
        eq: "${name}"
      }
    }) {
      items {
        id
        name
      }
    }
  }
  `;
};

export const getBonusByPosition = (id) => {
  return `
  query {
    listPositionBonuses(limit: 1000000, filter: {
      positionID: {
        eq: "${id}"
      }}) {
      items {
        id
        bonus {
          id
          name
          isPercentage
          value
          type
        }
        positionID
        bonusID
      }
    }
  }
  `;
};

export const valPositionByBonus = (id) => {
  return `
  query {
    listPositionBonuses(limit: 1000000, filter:{
      bonusID: {
        eq: "${id}"
      }
    }) {
      items {
        id
        positionID
        bonusID
      }
    }
  }
  `;
};

export const valNameBonus = (data) => {
  return `
  query {
    listBonuses(limit: 1000000, filter: {
      name: {
        eq: "${data}"
      }
    }) {
      items {
        id
        name
      }
    }
  }
  `;
};

export const adminReports = (data) => {
  return `
  query {
    listAdminReports(limit: 1000000, filter: {
      adminReportWellId: {
        eq: "${data}"
      }
    }) {
      items {
        id
        adminReportWellId
      }
    }
  }
  `;
};

export const dailyReports = (data) => {
  return `
  query report {
    listDailyWorkReports(limit: 1000000, filter: {
      dailyWorkReportWellId: {
        eq: "${data}"
      }
    }) {
      items {
        id
        dailyWorkReportWellId
      }
    }
  }
  `;
};

export const getReportById = (id, initialDate, finalDate) => {
  return `
  query {
    listAdminReports(limit: 1000000, filter: {
      operatorAdminWorkReportsId: {
        eq: "${id}"
      }, and: {
        dateReport: {
          between: ["${initialDate}}", "${finalDate}"]
        }
      }
    }) {
      items {
        id
        operator {
          id
          name
          surname
        }
        well {
          name
          jobNumber {
            name
            id
          }
        }
        dateReport
        duty
        entry
        out
      }
    }
  }
  `;
};

export const getNameById = (id) => {
  return `
  query {
    getOperator(id:"${id}") {
      id
      name
      surname
    }
  }
  `;
};
